import classNames from "classnames";
import { Link } from "react-router-dom";
import "./buttonSolid.scss";

interface buttonSolidProps {
  to: string;
  isSmall?: boolean;
  onClick?: () => void;
}

const ButtonSolid: React.FC<buttonSolidProps> = (props) => {
  return (
    <Link to={props.to} className={classNames("button-solid", {"is-small": props.isSmall})}>
      <div className="button-solid__move-el">
        <div className="button-solid__el">{props.children}</div>
        <div className="button-solid__el">{props.children}</div>
      </div>
    </Link>
  );
};

export default ButtonSolid;
