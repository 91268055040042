import classNames from "classnames";
import { useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import imagesLoaded from "imagesloaded";

import "./scroll.scss";
const lerp = (a: number, b: number, n: number) => (1 - n) * a + n * b;

interface scrollProps {
  className?: string;
}

const Scroll: React.FC<scrollProps> = (props) => {
  const [contentHeight, setContentHeight] = useState(0);
  //
  const scrollableRef = useRef<HTMLDivElement>(null);
  //
  const docScroll = useRef<number>(0);
  const current = useRef<number>(0);
  const scrollToRender = useRef<number>(0);
  const speedTarget = useRef<number>(0);
  const ease = 0.1;
  const aFrame = useRef<any>();
  const history = useHistory();

  useLayoutEffect(() => {
    const handleResize = () => {
      setContentHeight(scrollableRef.current?.scrollHeight || 0);
    };

    //
    handleResize();
    window.addEventListener("resize", handleResize);
    if (scrollableRef.current) {
      imagesLoaded(scrollableRef.current, () => {
        handleResize();
      });
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [contentHeight]);

  useLayoutEffect(() => {
    const setPosition = () => {
      if (
        Math.round(scrollToRender.current) !== Math.round(current.current) ||
        scrollToRender.current < 10
      ) {
        if (scrollableRef.current) {
          scrollableRef.current.style.transform = `translate3d(0,${
            -1 * scrollToRender.current
          }px,0)`;
        }
      }
    };

    const getScroll = () => {
      docScroll.current =
        window.pageYOffset || document.documentElement.scrollTop;
      return docScroll.current;
    };

    const render = () => {
      if (document.body.classList.contains('is-fixed')) {
        aFrame.current = requestAnimationFrame(render);
        return false;
      }
      const speed =
        Math.min(Math.abs(current.current - scrollToRender.current), 200) / 200;
      speedTarget.current += (speed - speedTarget.current) * 0.2;

      current.current = getScroll();
      let renderCurrent = lerp(
        scrollToRender.current,
        current.current,
        ease
      );
      renderCurrent = Math.floor(renderCurrent * 100000) / 100000;
      renderCurrent < 0 && (renderCurrent = 0);
      scrollToRender.current = renderCurrent;
      setPosition();
      window.spHotelScrollY = scrollToRender.current;
      aFrame.current = requestAnimationFrame(render);
    };

    aFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(aFrame.current);
  }, []);

  // location change
  useLayoutEffect(() => {
    return history.listen((location) => {
      window.spHotelScrollY = 0;
      // scrollToRender.current = 0;
      // current.current = 0;
    });
  }, [history]);

  return (
    <div className={classNames("scroll", props.className)} style={{height: `${contentHeight}px`}}>
      <div className="scroll__scrollable" ref={scrollableRef}>{props.children}</div>
    </div>
  );
};

export default Scroll;
