import "./legal.scss";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

interface legalProps {
  dataKey: string;
}

const Legal: React.FC<legalProps> = (props) => {
  const [data, setData] = useState<{
    content: Array<string>;
  } | null>(null);

  useEffect(() => {
    setData(require(`data/${props.dataKey}.data.json`));
  }, [data, props.dataKey]);

  const renderData = () => {
    return !!data && data.content.map((item, key) => ReactHtmlParser(item));
  }

  return (
    <div className="legal">
      <div className="legal-wrapper">{!!data && renderData()}</div>
    </div>
  );
};

export default Legal;
