import "./teaserExhibitions.scss";
import { teaserDataProps } from "./teaser";
import * as utils from "shell/app/app.utils";
import ButtonMove from "components/button/buttonMove";
import Cross from "components/cross/cross";
import classNames from "classnames";
import Image2k from "components/image2k/image2k";

interface teaserExhibitionsProps extends teaserDataProps {
  isIn: boolean;
}

const TeaserExhibitions: React.FC<teaserExhibitionsProps> = (props) => {
  return (
    <>
      <div className="teaser-block teaser-block--image">
        <Image2k src={utils.assetPath(props.images[0])} />
        <Cross className="teaser-cross--img" />
        <Cross className="teaser-cross--img-2" />
      </div>
      <div className="teaser-block teaser-block--copy">
        <h5>{props.title}</h5>
        {props.sponsorIcon && (
          <div
            className={classNames(
              "teaser-sponsor-icon",
              `teaser-sponsor-icon--${props.sponsor}`
            )}
          >
            <img src={utils.assetPath(props.sponsorIcon)} alt="" />
          </div>
        )}
        <p>{props.copy}</p>
        <ButtonMove
          to={props.link.href}
          withIcon
          className="teaser-block-button"
          isExternal
        >
          {props.link.label}
        </ButtonMove>
        <Cross className="teaser-cross--copy" />
      </div>
      {props.images.length > 1 && (
        <div className="teaser-block teaser-block--image">
          <Image2k src={utils.assetPath(props.images[1])} />
        </div>
      )}
    </>
  );
};

export default TeaserExhibitions;
