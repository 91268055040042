import classNames from "classnames";
import "./buttonMove.scss";

interface buttonMoveProps {
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
  isWhite?: boolean;
  isExternal?: boolean;
  withIcon?: boolean;
  to?: string;
}

const ButtonMove: React.FC<buttonMoveProps> = (props) => {
  const handleClick = () => {

  }

  const CustomTag = props.to ? 'a' : 'button';

  return (
    <CustomTag
      className={classNames("button-move", props.className, {
        "is-active": props.isActive,
        "is-white": !!props.isWhite,
        "with-icon": props.withIcon,
      })}
      onClick={props.onClick || handleClick}
      href={props.to}
      target={!!props.isExternal ? "_blank" : undefined}
    >
      <div className="button-move__area">
        <div className="button-move__inner">
          <span>{props.children}</span>
          <span>{props.children}</span>
        </div>
      </div>
      {props.withIcon && <div className="button-move__icon" />}
    </CustomTag>
  );
};

export default ButtonMove;
