import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";

import "./hotel.scss";
import data from "data/lobby.data.json";

import * as utils from "shell/app/app.utils";
import HotelTitle from "./hotelTitle";
import isMobile from "ismobilejs";

interface hotelProps {
  isBlur: boolean;
}

const Hotel: React.FC<hotelProps> = (props) => {
  const [scrollUnit, setScrollUnit] = useState({ section: 0, frame: 0 });
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const elRef = useRef<HTMLDivElement>(null!);
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  const containerRef = useRef<HTMLDivElement>(null!);
  const animationFrameRef = useRef<any>();

  const scrubSection = useRef<number>(0);
  const scrubFrame = useRef<number>(0);

  const isMobileDevice = useRef<boolean>(isMobile().any);

  // draw on scroll and resize
  useEffect(() => {
    //draw
    const draw = () => {
      const canvas = canvasRef.current;
      const context = canvas && canvas.getContext("2d");
      if (context) {
        const img = new Image();
        const frame = scrubFrame.current || 0;
        const section = scrubSection.current || 0;
        img.src = utils.assetPathHotel(frame, data.sections[section].folder, isMobileDevice.current);
        img.onload = () => {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
        };
      }
    };
    // render
    const render = () => {
      if (
        window.spHotelScrubSection !== scrubSection.current ||
        window.spHotelScrubFrame !== scrubFrame.current
      ) {
        if (scrubSection.current !== window.spHotelScrubSection) {
          scrubSection.current = window.spHotelScrubSection;
          const imageCount = data.sections[window.spHotelScrubSection].imageCount;
          if (window.spHotelScrubFrame > imageCount/2) {
            scrubFrame.current = imageCount;
          } else {
            scrubFrame.current = 0;
          }
        } else {
          if (window.spHotelScrubFrame < scrubFrame.current) {
            scrubFrame.current -= 1;
          } else {
            scrubFrame.current += 1;
          }
        }

        setScrollUnit({
          section: scrubSection.current,
          frame: scrubFrame.current,
        });
        draw();
      }
      animationFrameRef.current = requestAnimationFrame(render);
    };
    draw();
    animationFrameRef.current = requestAnimationFrame(render);
    window.addEventListener("resize", draw);
    return () => {
      cancelAnimationFrame(animationFrameRef.current);
      window.removeEventListener("resize", draw);
    };
  }, []);

  // init
  useLayoutEffect(() => {
    const handleResize = () => {
      const ratio = 16 / 9;
      const container = containerRef.current.getBoundingClientRect();
      if (container.width / container.height >= ratio) {
        setCanvasSize({
          height: container.height,
          width: container.height * ratio,
        });
      } else {
        setCanvasSize({
          height: container.width / ratio,
          width: container.width,
        });
      }
    };
    //
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={classNames("hotel", {
        "is-blur": props.isBlur,
      })}
      ref={elRef}
    >
      {/* TITLE */}
      {/* {!!scrollUnit.frame && ( */}
        <HotelTitle
          sectionActive={
            scrollUnit.frame > data.sections[scrollUnit.section].imageCount - 10
              ? scrollUnit.section
              : -1
          }
          isBlur={props.isBlur}
        />
      {/* )} */}
      {/* END TITLE */}
      <div className="hotel__wrapper">
        <div className="hotel__container" ref={containerRef}>
          <div className="hotel__scale">
            <canvas
              ref={canvasRef}
              width={canvasSize.width}
              height={canvasSize.height}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hotel;
