import classNames from "classnames";

import "./cross.scss";

interface crossProps {
  className?: string;
  style?: React.CSSProperties | undefined;
}

const Cross: React.FC<crossProps> = (props) => (
  <div className={classNames("cross", props.className)} style={props.style} />
);

export default Cross;
