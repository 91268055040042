import classNames from "classnames";
import HotelTitleNum from "components/hotel/hotelTitleNum";
import { useEffect, useState } from "react";
import "./pageTitleSmall.scss";

interface pageTitleSmallProps {
  isOpen?: boolean;
  onPage?: boolean;
  onOverlay?: boolean;
  title: string;
  titleNum?: number;
}

const PageTitleSmall: React.FC<pageTitleSmallProps> = (props) => {
  const [isOut, setIsOut] = useState<boolean>(!!props.onPage);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 0 ? setIsOut(true) : setIsOut(false);
    };
    if (!!props.onPage) {
      handleScroll();
      document.addEventListener('scroll', handleScroll);
    }
    return () => document.removeEventListener('scroll', handleScroll);
  }, [isOut, props.onPage]);

  return (
    <div
      className={classNames("page-title-small", {
        "is-on-page": !!props.onPage,
        "is-on-overlay": !!props.onOverlay,
        "is-open": !!props.isOpen && !isOut,
      })}
    >
      <div className="page-title-small__inner">
        {!!props.titleNum && <HotelTitleNum index={props.titleNum} />}
        <h1>{props.title}</h1>
      </div>
    </div>
  );
};

export default PageTitleSmall;
