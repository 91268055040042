import { useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";

import "./lobby3dCanvas.scss";
import "shaders/imageWallMaterial";
import "shaders/waveMaterial";
import Lobby3dImage from "./lobby3dImage";
import Lobby3dCamera from "./lobby3dCamera";

const Lobby3dCanvas = props => {
  const [canvasSize, setCanvasSize] = useState({
    height: 0,
    width: 0,
  });
  const [imageStore, setImageStore] = useState([]);
  //
  const elRef = useRef();
  // init
  useEffect(() => {
    // get all images
    const setImages = () => {
      const images = [...document.querySelectorAll(".lobby-canvas-image")];
      setImageStore(
        images.map((img) => {
          img.setAttribute('style', 'opacity: 0;')
          // console.log(img.getAttribute("data-debug") === "true")
          const isRotation = img.getAttribute("data-is-rotation") === "true";
          return {
            img: img,
            debug: img.getAttribute("data-debug") === "true",
            isRotation: isRotation,
            offset: img
              .getAttribute("data-offset")
              .split(",")
              .map((offset) => parseInt(offset)),
            plx: parseFloat(img.getAttribute("data-plx") || 0),
            src: img.getAttribute("src"),
            z: parseFloat(img.getAttribute("data-z") || 0) + (isRotation ? 0 : 0),
          };
        })
      );
    }
    // do the resize
    const handleResize = () => {
      if (elRef.current) {
        setCanvasSize({
          height: elRef.current.offsetHeight,
          width: elRef.current.offsetWidth,
        });
      }
    };
    // actions
    if (!imageStore.length) {
      handleResize();
      setImages();
    }
    //  event listeners
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imageStore, canvasSize]);

  return (
    <div className="lobby3d-canvas" ref={elRef}>
      <Canvas
        colorManagement
        shadowMap
        gl={{ antialias: false }}
        dpr={window.devicePixelRatio}
        linear={true}
      >
        <Lobby3dCamera canvasSize={canvasSize} isZoom={props.isZoom} />
        {!!imageStore.length &&
          imageStore.map((item, key) => {
            return (
              <Lobby3dImage
                {...item}
                canvasSize={canvasSize}
                key={key}
              />
            );
          })}
      </Canvas>
    </div>
  );
};

export default Lobby3dCanvas;
