import { PerspectiveCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import isMobile from "ismobilejs";
import { gsap, Cubic } from "gsap";

const Lobby3dCamera = (props) => {
  let [mouse, setMouse] = useState({ x: 0, y: 0 });
  let target = {x: 0, y: 0};
  const elRef = useRef();
  const z = 1400;
  const zoomTarget = useRef(0);

  const zoomAnimated = useRef({zoom: 1});

  useEffect(() => {
    if (props.isZoom) {
      zoomTarget.current = 1.5;
      gsap.to(zoomAnimated.current, 0.6, {zoom: 1.5, ease: Cubic.easeIn});
    } else {
      zoomTarget.current = 1;
      gsap.to(zoomAnimated.current, 0.6, {zoom: 1, ease: Cubic.easeOut});
    }
  }, [props.isZoom]);

  useFrame(() => {
    const camera = elRef.current;
    if (window.spHotelSectionActive === 0) {
      target.x = 0;
      target.y = 0;
    } else {
      target.x = (1 - mouse.x) * 0.0005;
      target.y = (1 - mouse.y) * 0.0005;
    }

    camera.rotation.x -= 0.025 * (target.y + camera.rotation.x);
    camera.rotation.y -= 0.025 * (target.x + camera.rotation.y);

    if (camera.zoom !== zoomAnimated.current.zoom) {
      camera.zoom = zoomAnimated.current.zoom;
      camera.updateProjectionMatrix();
    }
  });

  useLayoutEffect(() => {
    const handleMouseMove = (e) => {
      setMouse({
        x:
          (100 / (window.innerWidth / 2)) * (e.clientX - window.innerWidth / 2),
        y:
          (100 / (window.innerHeight / 2)) *
          (e.clientY - window.innerHeight / 2),
      });
    };

    !isMobile.any && document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <PerspectiveCamera
      aspect={props.canvasSize.width / props.canvasSize.height}
      position={[props.canvasSize.width / 2, 0, z]}
      fov={2 * Math.atan(props.canvasSize.height / 2 / z) * (180 / Math.PI)}
      makeDefault
      near={0.1}
      far={2000}
      ref={elRef}
      zoom={1}
    />
  );
};

export default Lobby3dCamera;
