import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import Preloader from "components/preloader/preloader";
import Topbar from "components/topbar/topbar";

import "./app.scss";
import Menu from "components/menu/menu";
import Transition from "shell/transition/transition";
import { MENU_PANEL } from "const/menuPanel.const";
import classNames from "classnames";
import Router from "shell/router/router";
import isMobile from "ismobilejs";

declare global {
  interface Window {
    spHotelScrollY: number;
    spHotelScrubSection: number;
    spHotelScrubFrame: number;
    spHotelSectionActive: number;
    spHotelHotelMobile: boolean;
  }
}

const App: React.FC<{}> = () => {
  const history = useHistory();
  //
  const [doneLoading, setDoneLoading] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isTransitionOpen, setTransitionOpen] = useState<boolean>(true);
  const [page, setPage] = useState<string>(history.location.pathname);
  //
  const appRef = useRef<HTMLDivElement>(null);
  //
  window.spHotelScrollY = 0;
  window.spHotelScrubSection = 0;
  window.spHotelScrubFrame = 0;
  window.spHotelSectionActive = 0;

  // INIT
  useLayoutEffect(() => {
    // scroll to top
    // window.scrollTo(0, 0);
    // resize (set custom win height)
    const handleResize = () => {
      document.documentElement.style.setProperty(
        "--win-height",
        `${window.innerHeight}px`
      );
    };
    //
    isMobile().any && document.body.classList.add('is-mobile');
    //
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // HISTORY
  useEffect(() => {
    const handleHistoryChange = () => {
      if (page !== history.location.pathname) {
        if (isMenuOpen) {
          setMenuOpen(false);
          setPage(history.location.pathname);
        } else {
          // if (window.scrollY > document.body.clientHeight - window.innerHeight*2) {
          //   window.scrollTo(0,document.body.clientHeight);
          // }
          setTransitionOpen(true);
          setTimeout(() => {
            setPage(history.location.pathname);
            setTransitionOpen(false);
          }, MENU_PANEL.tDuration * 1000 * 2);
        }
      }
    };
    return history.listen(handleHistoryChange);
  }, [history, isTransitionOpen, isMenuOpen, page]);
  //
  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setMenuOpen(false);
      }
    }
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [isMenuOpen])
  //
  return (
    <div className={classNames("app", {"is-loaded": doneLoading}, `page--${page.length === 1 ? "lobby" : page.replace('/', '')}`)} ref={appRef}>
      {/* PRELOADER */}
      <Preloader onDoneLoading={() => {
        setDoneLoading(true);
        setTransitionOpen(false);
        }} />

      {/* TRANSITION LAYER */}
      <Transition isOpen={isTransitionOpen || isMenuOpen} />

      {/* MENU */}
      {doneLoading && <Menu isOpen={isMenuOpen} onMenuClose={() => setMenuOpen(false)} />}

      {/* TOP BAR */}
      <Topbar onMenuOpen={() => setMenuOpen(true)} isMenuOpen={isMenuOpen} />
      

      {/* SWITCH && FOOTER */}
      {!!doneLoading && (
        <>
          <div className="app-content">
            <Router page={page} />
          </div>
        </>
      )}
    </div>
  );
};

export default App;
