import "./scheduleTeaser.scss";

import * as utils from "shell/app/app.utils";
import classNames from "classnames";
import Image2k from "components/image2k/image2k";

interface scheduleTeaserProps {
  date?: string;
  time: {
    time: string;
    ampm: string;
  };
  speakers: Array<{
    name: string;
    image: string;
  }>;
  content: {
    title?: string;
    copy: Array<string>;
  };
  sponsor?: string;
}

const ScheduleTeaser: React.FC<scheduleTeaserProps> = (props) => {
  const timeAreas = new Array(3);
  const timeLines = new Array(10);

  return (
    <div
      className={classNames(
        "schedule-teaser",
        `schedule-teaser__count-${props.speakers.length}`
      )}
    >
      <div className="schedule-teaser__wrapper">
        <div className="schedule-teaser__inner">
          {/* TIME */}
          <div className="schedule-teaser-time schedule-teaser__box">
            <div className="schedule-teaser-time__inner">
              <div className="schedule-teaser-time__markers">
                {Array.from(timeAreas.keys()).map((item, key) => (
                  <div key={key}>
                    {Array.from(timeLines.keys()).map((item, key) => (
                      <div key={key} />
                    ))}
                  </div>
                ))}
              </div>
              {/* DATE */}
              {!!props.date && (
                <div className="schedule-teaser-date">{props.date}</div>
              )}
              {/* END DATE */}
              <div className="schedule-teaser-time__time">
                {props.time.time}
                <small>{props.time.ampm}</small>
              </div>
            </div>
          </div>
          {/* SPEAKERS */}
          <div className="schedule-teaser__row">
            {props.speakers.map((item, key) => (
              <div
                className="schedule-teaser-speaker schedule-teaser__box"
                key={key}
              >
                <h5 className="schedule-teaser-speaker__name">{item.name}</h5>
                <Image2k src={utils.assetPath(item.image)} />
              </div>
            ))}
            {/* CONTENT */}
            <div className="schedule-teaser-content schedule-teaser__box">
              <div className="schedule-teaser-content__inner">
                {!!props.sponsor && (
                  <>
                    <p className="small">presented by</p>
                    <img
                      src={utils.assetPath(props.sponsor)}
                      className="schedule-teaser-content__sponsor"
                      alt=""
                    />
                  </>
                )}
                {props.content.copy.map((item, key) => (
                  <p key={key}>{item}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTeaser;
