import "./schedule.scss";
import data from "data/schedule.data.json";
import Scroll from "shell/scroll/scroll";
import PageHeader from "components/page/pageHeader";
import ScheduleTeaser from "components/schedule/scheduleTeaser";
import Footer from "components/footer/footer";
import Cross from "components/cross/cross";
import { useEffect, useState } from "react";
import PageTitleSmall from "components/page/pageTitleSmall";

interface scheduleProps {}

const Schedule: React.FC<scheduleProps> = (props) => {
  const [isInit, setInit] = useState<boolean>(false);

  useEffect(() => {
    if (!isInit) {
      window.scrollTo(0,0);
      setInit(true);
    }
  }, [isInit]);

  return (
    <div className="schedule">
      <PageTitleSmall {...data.header} onPage isOpen={isInit} />
      <Scroll>
        <PageHeader data={data.header} isIn={!!isInit} />
        <div className="schedule-future">
          <div className="wrapper">
            <div className="schedule-future__inner">
              <h5>{data.futureTalks.headline.map((item, key) => <span key={key}>{item}</span>)}</h5>
              <p>{data.futureTalks.copy}</p>
            </div>
          </div>
        </div>
        <div className="schedule__teasers">
          {data.teasers.map((item, key) => (
            <ScheduleTeaser {...item} key={key} />
          ))}
        </div>
        <div className="schedule-rooms">
          {data.rooms.map((item, key) => (
            <div className="schedule-room" key={key}>
              <div className="wrapper">
                <div className="schedule-room__inner grid-row">
                  <div className="grid-cell">
                    <h5>{item.room}</h5>
                  </div>
                  <div className="grid-cell">
                    <h5>{item.title}</h5>
                    <span>{item.subtitle}</span>
                    <p>{item.copy}</p>
                  </div>
                  <Cross className="schedule-room__cross" />
                  <Cross className="schedule-room__cross--top" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <Footer />
      </Scroll>
    </div>
  );
};

export default Schedule;
