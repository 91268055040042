import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import isMobile from "ismobilejs";

import "./lobby.scss";
import data from "data/lobby.data.json";
import { ReactComponent as Logo } from "static/logo.svg";
import ImageWall from "components/imageWall/imageWall";
import Lobby3dCanvas from "components/lobby3d/lobby3dCanvas";
import Hotel from "components/hotel/hotel";
import * as utils from "shell/app/app.utils";
import LobbyOverlay from "components/lobbyOverlay/lobbyOverlay";
import Scroll from "shell/scroll/scroll";
import HotelScrub from "components/hotel/hotelScrub";
import ButtonSolid from "components/button/buttonSolid";
import Footer from "components/footer/footer";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import toLower from "lodash/toLower";
import LobbyOverlayBottom from "components/lobbyOverlay/lobbyOverlayBottom";

interface lobbyProps {}

const Lobby: React.FC<lobbyProps> = (props) => {
  const [imageWallProps, setImageWallProps] = useState(
    data.sections.map(() => ({ inVP: false }))
  );
  const [init, setInit] = useState<boolean>(false);
  const [iOverlayOpen, setOverlayOpen] = useState(-1);
  const [scrubHeight, setScrubHeight] = useState(0);
  const [sectionActive, setSectionActive] = useState(0);
  //
  const lobbyRef = useRef<HTMLDivElement>(null!);
  const hotelTriggerRef = useRef<HTMLDivElement>(null!);
  const animationFrameRef = useRef<any>(null);
  const scrubs = useRef<Array<HTMLDivElement | null>>([]);
  const dotNavAr = useRef<Array<HTMLDivElement | null>>([]);
  const history = useHistory();
  const mobileDevice = isMobile().any;
  //
  const handleImageWallScroll = (section: number, inVP: boolean) => {
    const _imageWall = [...imageWallProps];
    _imageWall[section].inVP = inVP;
    setImageWallProps(_imageWall);
    //
  };
  // **************
  // section active
  useEffect(() => {
      !init && setInit(true);
  }, [init]);
  useEffect(() => {
    let imageWallInFocus = -1;
    imageWallProps.forEach((item, key) => {
      if (!!item.inVP) imageWallInFocus = key;
    });
    if (imageWallInFocus !== iOverlayOpen) {
      setOverlayOpen(imageWallInFocus);
    }
  }, [imageWallProps, iOverlayOpen]);
  useEffect(() => {
    return history.listen(() => {
      document.body.classList.remove("is-topbar-transparent");
    });
  }, [history]);
  // **************
  // ENTER FRAME - animate hotel up
  useEffect(() => {
    const render = () => {
      // HOTEL TRIGGER
      if (hotelTriggerRef.current && lobbyRef.current) {
        const hotelBox = hotelTriggerRef.current.getBoundingClientRect();
        const hotelTriggerY =
          (window.innerHeight - hotelBox.top) / hotelBox.height;
        lobbyRef.current.setAttribute(
          "style",
          `--hotel-trigger-value: ${utils.minMax(hotelTriggerY).toFixed(5)}`
        );
      }
      // scrub containers
      let scrubIn = 0;
      let frame = 0;
      let sectionIn = 0;
      scrubs.current.forEach((item, key) => {
        if (item) {
          const box = item.getBoundingClientRect();
          const posY = -box.top + window.innerHeight;
          if (posY >= 0) {
            scrubIn = key;
          }
          if (posY >= window.innerHeight) {
            sectionIn = key + 1;
          }
        }
      });

      let scrubItem = scrubs.current[scrubIn];
      if (scrubItem) {
        const box = scrubItem.getBoundingClientRect();
        const posY = -box.top + window.innerHeight;
        const imgCount = data.sections[scrubIn].imageCount;
        frame = Math.floor((imgCount / box.height) * posY);
        if (frame < 0) {
          frame = 0;
        } else if (frame > imgCount) {
          frame = imgCount;
        }
      }
      // section in for dotnav
      dotNavAr.current.forEach((item, key) => {
        if (key === sectionIn) {
          item?.classList.add("is-current");
        } else {
          item?.classList.remove("is-current");
        }
      });
      sectionIn > 0
        ? document.body.classList.add("is-topbar-transparent")
        : document.body.classList.remove("is-topbar-transparent");

      sectionActive !== sectionIn && setSectionActive(sectionIn);
      window.spHotelSectionActive = sectionIn;
      window.spHotelScrubSection = scrubIn;
      window.spHotelScrubFrame = frame;
      animationFrameRef.current = requestAnimationFrame(render);
    };
    animationFrameRef.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [sectionActive]);
  // win height
  useLayoutEffect(() => {
    const handleResize = () => {
      setScrubHeight(
        mobileDevice ? window.innerHeight * 0.04 : window.innerHeight * 0.05
      );
    };
    handleResize();
    // window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);
  }, [mobileDevice]);

  return (
    <div className={classNames("lobby", {"is-init": !!init}, {"is-overlay-open": iOverlayOpen !== -1})} ref={lobbyRef}>
      {/* INTRO BLOCK */}
      <Scroll className="lobby__scroll">
        <div className="lobby__block">
          <ImageWall data={data.introWall} map3d={true} />
          <div className="lobby__first">
            <div className="lobby__logo">
              <Logo />
            </div>
            <div className="lobby__intro">
              <h1>{data.intro}</h1>
            </div>
            <div className="lobby__sponsors">
              <img src={utils.assetPath("/icons/sponsors.svg")} alt="" />
            </div>
          </div>
        </div>
        <div className="lobby-hotel-trigger" ref={hotelTriggerRef} />
        {/* SECTIONS */}
        {data.sections.map((item, key: number) => (
          <div key={key} id={toLower(item.title)}>
            <HotelScrub
              index={key}
              unitCount={item.imageCount}
              unitHeight={scrubHeight}
              ref={(ref) => (scrubs.current[key] = ref)}
            />
            {!!item.title && (
              <div className="lobby-section">
                <div className="lobby-section__inner">
                  <div className="lobby-section__image-wall-spacer" />
                  <ImageWall
                    data={data.sectionWalls[key]}
                    section={key}
                    onScroll={handleImageWallScroll}
                    debug={key === 0}
                    key={`wall-${key}`}
                    map3d={true}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        {/* FOOTER */}
        <Footer />
      </Scroll>
      {/* CANVAS */}
      {/* !isMobile().any &&  */}
      {<Lobby3dCanvas isZoom={sectionActive > 0 && iOverlayOpen === -1} />}
      {/* SECTION OVERLAYS */}
      {data.overlays.map((item, key) => (
        <LobbyOverlay {...item} key={key} isIn={key === iOverlayOpen} />
      ))}
      {data.overlays.map((item, key) => (
        <LobbyOverlayBottom {...item} key={`o-${key}`} isIn={key === iOverlayOpen} />
      ))}
      {/* HOTEL */}
      <Hotel isBlur={iOverlayOpen !== -1} />

      {/* SCHEDULE BUTTON */}
      <div className="lobby-schedule-button">
        <ButtonSolid to={`${data.speakerButton.link}`} isSmall>
          Schedule
        </ButtonSolid>
      </div>

      {/* DOTNAV */}
      <div className="lobby-dotnav">
        {data.sections.map((item, key) => (
          <div
            ref={(ref) => (dotNavAr.current[key] = ref)}
            key={key}
            className="lobby-dotnav__item"
          />
        ))}
      </div>
    </div>
  );
};

export default Lobby;
