import { extend } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";

const ImageWallMaterial = shaderMaterial(
  {
    uiImage: undefined,
    dispFactor: 0,
  },
  `varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }`,
  `varying vec2 vUv;
  uniform sampler2D uiImage;
  uniform float dispFactor;

  float random (vec2 st) {
    return fract(sin(dot(st.xy,
                         vec2(12.9898,78.233)))*
        43758.5453123);
    }

  void main() {
    vec2 uv = vUv;

    uv = fract(uv);

    vec3 color = vec3(
      texture2D(uiImage, uv).r,
      texture2D(uiImage, uv + vec2(0.01,0.01)*dispFactor).g,
      texture2D(uiImage, uv).b
    );
  
    gl_FragColor = vec4(color,1.0);
  }`
);

extend({ ImageWallMaterial })



    // vec2 uv = vUv;
    // vec2 distortedPosition = vec2(uv.x, uv.y + dispFactor);
    // vec4 _texture = texture2D(uiImage, distortedPosition);
    // gl_FragColor = _texture;