import { extend } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";
import glsl from "babel-plugin-glsl/macro";

export const WaveMaterial = shaderMaterial(
  {
    uiImage: undefined,
    uDispFactor: 0,
    uOpacity: 1,
  },
  glsl`
  // Shader
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
  `,
  glsl`
  varying vec2 vUv;
  uniform sampler2D uiImage;
  uniform float uDispFactor;
  uniform float uOpacity;
  void main() {
    vec2 uv = vUv;
    uv = fract(uv);
    vec3 color = vec3(
      texture2D(uiImage, uv).r,
      texture2D(uiImage, uv).g,
      texture2D(uiImage, uv).b
    );
    // vec4 texture = texture2D(uiImage, vUv);
    gl_FragColor = vec4(color,uOpacity);
    // gl_FragColor = texture;
  }
 `
);

extend({ WaveMaterial });
