import React, { useLayoutEffect, useRef, useState } from "react";
import data from "data/lobby.data.json";
import classNames from "classnames";

import "./hotelTitle.scss";
import * as utils from "shell/app/app.utils";
import HotelTitleNum from "components/hotel/hotelTitleNum";

interface lobbyTitleProps {
  isBlur: boolean;
  sectionActive: number;
}

const HotelTitle: React.FC<lobbyTitleProps> = (props) => {
  const [itemHeight, setItemHeight] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const firstItemRef = useRef<HTMLImageElement | null>(null!);

  useLayoutEffect(() => {
    const handleResize = () => {
      firstItemRef.current &&
        setItemHeight(firstItemRef.current.getBoundingClientRect().height);
    };
    //
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isLoaded]);

  return (
    <div className={classNames("hotel-title", { "is-blur": props.isBlur })}>
      <div className="hotel-title__wrapper">
        <div className="hotel-title__inner">
          {data.sections.map((item, key) => {
            if (item.title) {
              return (
                <div
                  className={classNames("hotel-title-item", {
                    "is-in": key === props.sectionActive,
                  })}
                  key={key}
                >
                  <div className="hotel-title-item__num">
                    <HotelTitleNum index={key + 1} />
                  </div>
                  <div className="hotel-title-item__desktop">
                    <img
                      src={utils.assetPath(item.svg)}
                      className={classNames("hotel-title-item__desktop-svg", {
                        "is-first": key === 0,
                      })}
                      ref={(ref) =>
                        key === 0 ? (firstItemRef.current = ref) : null
                      }
                      style={{ height: `${itemHeight}px` }}
                      alt=""
                      onLoad={() => key === 0 && setIsLoaded(true)}
                    />
                  </div>
                  <div className="hotel-title-item__mobile">
                    {item.svgMobile.map((item, key) => (
                      <div className="hotel-title-item__mobile-item" key={key}>
                        <img
                          src={utils.assetPath(item)}
                          className="hotel-title-item__mobile-item-svg"
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            }
            return false;
          })}
        </div>
      </div>
    </div>
  );
};

export default HotelTitle;
