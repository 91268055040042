import classNames from "classnames";
import Marquee from "components/marquee/marquee";
import PageTitleSmall from "components/page/pageTitleSmall";
import isMobile from "ismobilejs";
import { useRef } from "react";
import "./lobbyOverlayBottom.scss";

interface lobbyOverlayBottomProps {
  marquee: string;
  title: string;
  titleNum: number;
  isIn: boolean;
}

const LobbyOverlayBottom: React.FC<lobbyOverlayBottomProps> = (props) => {
  const onMobile = useRef<boolean>(isMobile().any);

  return (
    <div
      className={classNames("lobby-overlay-bottom", { "is-in": props.isIn })}
    >
      <PageTitleSmall
        title={props.title}
        titleNum={props.titleNum}
        onOverlay
        isOpen={props.isIn}
      />
      {!onMobile.current && (
        <div className="lobby-overlay-bottom__marquee">
          <Marquee copy={props.marquee} isActive={props.isIn} />
        </div>
      )}
    </div>
  );
};

export default LobbyOverlayBottom;
