import "./pageHeader.scss";
import * as utils from "shell/app/app.utils";
import classNames from "classnames";
import Image2k from "components/image2k/image2k";
import { useEffect, useRef } from "react";
import isMobile from "ismobilejs";

export interface pageHeaderData {
  headlines: Array<string>;
  copy: Array<string>;
  layout: string;
  title: string;
  titleNum?: number;
  img: string;
  isSponsorsUnderImage?: boolean;
  isSponsorsOverImage?: boolean;
}

interface pageHeaderProps {
  data: pageHeaderData;
  isIn: boolean;
}

const PageHeader: React.FC<pageHeaderProps> = (props) => {
  const hlRef = useRef<HTMLDivElement>(null!);
  const animationFrame = useRef<any>();
  const mobileDevice = isMobile().any;

  useEffect(() => {
    const render = () => {
      const hl = hlRef.current;
      if (hl) {
        let fY = window.spHotelScrollY / window.innerHeight;
        fY = utils.minMax(fY, 0, 1);
        hl.style.transform = `translateY(${-40 * fY}vh)`;
      }
      animationFrame.current = requestAnimationFrame(render);
    };
    !mobileDevice && (animationFrame.current = requestAnimationFrame(render));
    return () => cancelAnimationFrame(animationFrame.current);
  }, [mobileDevice]);

  const renderInner = () => {
    let comp = null;
    switch (props.data.layout) {
      case "exhibitions":
        comp = (
          <PageHeaderContentExhibition {...props.data} isIn={props.isIn} />
        );
        break;

      case "salon":
        comp = <PageHeaderContentSalon {...props.data} isIn={props.isIn} />;
        break;

      case "schedule":
        comp = <PageHeaderContentSchedule {...props.data} isIn={props.isIn} />;
        break;
    }
    return comp;
  };

  return (
    <div
      className={classNames(
        "page-header",
        `page-header--${props.data.layout}`,
        { "is-in": !!props.isIn }
      )}
    >
      {/* HEADLINES */}
      <div className="page-header__inner wrapper--no-max">
        <div className="page-header-headline" ref={hlRef}>
          <h2>
            {props.data.headlines.map((item, key) => (
              <span key={key}>{item}</span>
            ))}
          </h2>
        </div>
        <div className="page-header__content grid-row">{renderInner()}</div>
      </div>
    </div>
  );
};

interface pagerHeaderContentProps extends pageHeaderData {
  isIn: boolean;
}

const PageHeaderContentExhibition: React.FC<pagerHeaderContentProps> = (
  props
) => {
  return (
    <>
      <div className="page-header-grid-cell grid-cell">
        <p>{props.copy[0]}</p>
        {!!props.copy[1] && !!props.isSponsorsOverImage && <p>{props.copy[1]}</p>}
      </div>
      <div className="page-header-grid-cell grid-cell">
        {!!props.isSponsorsOverImage &&  (
          <img src={utils.assetPath("/icons/sponsors.svg")} alt="" className="page-header-sponsors" />
        )}
        <Image2k src={utils.assetPath(props.img)} isHeader={true} />
        {!!props.copy[1] && !props.isSponsorsOverImage && <p>{props.copy[1]}</p>}
        {!!props.copy[2] && <p>{props.copy[2]}</p>}
        {!!props.isSponsorsUnderImage &&  (
          <img src={utils.assetPath("/icons/sponsors.svg")} alt="" className="page-header-sponsors" />
        )}
      </div>
      <div className="page-header-grid-cell grid-cell">
        {!props.isSponsorsUnderImage && !props.isSponsorsOverImage &&  (
          <>
            <img
              src={utils.assetPath(props.img)}
              alt=""
              className="is-hidden"
            />
            <img src={utils.assetPath("/icons/sponsors.svg")} alt="" className="page-header-sponsors" />
          </>
        )}
      </div>
    </>
  );
};

const PageHeaderContentSalon: React.FC<pagerHeaderContentProps> = (props) => {
  return (
    <>
      <div className="page-header-grid-cell grid-cell">
        <Image2k src={utils.assetPath(props.img)} isHeader={true} />
        <p>{props.copy[0]}</p>
        <img
          src={utils.assetPath("/icons/sponsors.svg")}
          alt=""
          className="page-header-sponsors"
        />
      </div>
    </>
  );
};

const PageHeaderContentSchedule: React.FC<pagerHeaderContentProps> = (
  props
) => {
  return (
    <>
      <div className="page-header-grid-cell grid-cell">
        <p>{props.copy[0]}</p>
      </div>
      <div className="page-header-grid-cell grid-cell">
        <Image2k src={utils.assetPath(props.img)} isHeader={true} />
        <img src={utils.assetPath("/icons/sponsors.svg")} alt="" className="page-header-sponsors"/>
      </div>
    </>
  );
};

export default PageHeader;
