import data from "data/studio.data.json";
import PageHeader from "components/page/pageHeader";
import TeaserLoop from "components/teaser/teaserLoop";
import Scroll from "shell/scroll/scroll";
import Footer from "components/footer/footer";
import { useEffect, useState } from "react";
import PageTitleSmall from "components/page/pageTitleSmall";

interface exhibitionsProps {}

const Studio: React.FC<exhibitionsProps> = (props) => {
  const [isInit, setInit] = useState<boolean>(false);

  useEffect(() => {
    if (!isInit) {
      window.scrollTo(0,0);
      setInit(true);
    }
  }, [isInit]);

  return (
    <>
      <PageTitleSmall {...data.header} onPage isOpen={isInit} />
      <Scroll className="scroll--page">
        <PageHeader data={data.header} isIn={!!isInit} />
        <TeaserLoop data={data.teasers} layout="exhibitions" />
        <Footer />
      </Scroll>
    </>
  );
};

export default Studio;
