import React, { useEffect, useRef, useState } from "react";
import FontFaceObserver from "fontfaceobserver";
import classNames from "classnames";

import "./preloader.scss";
import * as utils from "shell/app/app.utils";

//data
import dataLobby from "data/lobby.data.json";
import isMobile from "ismobilejs";
import Cross from "components/cross/cross";

interface preloaderProps {
  onDoneLoading: () => void;
}

const Preloader: React.FC<preloaderProps> = (props) => {
  const [isFontLoaded, setFontsLoaded] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [images, setImages] = useState<Array<Array<string>>>([]);
  const [isComplete, setComplete] = useState<boolean>(false);

  const iLoadPos = useRef<number>(0);
  const loadCount = useRef<Array<number>>();
  const onDoneLoading = props.onDoneLoading;

  // const loadCount = useRef<number>(0);
  const isMobileDevice = useRef<boolean>(isMobile().any);

  useEffect(() => {
    const handleLoaded = () => {
      const _loadPos = iLoadPos.current;
      if (loadCount.current) {
        const _load = loadCount.current[_loadPos] + 1;
        loadCount.current[_loadPos] = _load;
        // set load count for first item
        if (_loadPos === 0) {
          setLoaded(_load);
          if (_load === images[0].length) {
            onDoneLoading();
            setComplete(true);
          }
        }
        // initiate next load cycle
        if (loadCount.current[_loadPos] === images[_loadPos].length) {
          if (iLoadPos.current < images.length - 1) {
            console.log(':: loaded', iLoadPos.current);
            iLoadPos.current = iLoadPos.current + 1;
            load();
          }
        }
      }
    };

    const load = () => {
      images[ iLoadPos.current ].forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = handleLoaded;
        img.onerror = (e) => {
          console.log("ERROR", e);
          handleLoaded();
        };
      });
    };
    // start the loading proccess
    if (!!isFontLoaded && images.length && !loadCount.current) {
      loadCount.current = [];
      images.forEach(() => loadCount.current?.push(0));
      setTotal(images[0].length)
      load();
    }
  }, [images, iLoadPos, props, isFontLoaded, onDoneLoading]);

  useEffect(() => {
    if (!isFontLoaded) {
      const font = new FontFaceObserver("Titling");
      font
        .load("0123456789", 5000)
        .then(() => {
          console.log("font has been loaded");
          setFontsLoaded(true);
        })
        .catch((error) => {
          console.log("font has not been loaded");
          setFontsLoaded(true);
        });
    } else {
      document.body.classList.add("is-fonts-loaded");
    }
  }, [isFontLoaded]);

  useEffect(() => {
    const hotelImages: any = dataLobby.sections.map((item) => {
      let _ar: Array<string> = [];
      for (let i = 0; i <= item.imageCount; i++) {
        const url = utils.assetPathHotel(
          i,
          item.folder,
          isMobileDevice.current
        );
        _ar.push(url);
      }
      return _ar;
    });

    // intro wall
    const introWallItems = dataLobby.introWall.map((item, key) => {
      return utils.assetPath(item.img);
    });

    const sectionItems = dataLobby.sectionWalls.map((item, key) => {
      return item.map((item: any, key: number) => {
        return utils.assetPath(item.img);
      });
    });

    const wallItems = [...introWallItems, ...sectionItems.flat()];
    const _images = [wallItems.flat(), ...hotelImages];
    setImages(_images);
  }, []);

  return (
    <div
      className={classNames("preloader", {
        "is-visible": images.length !== loaded && total > 0,
        "is-complete": isComplete,
        "is-font-loaded": !!isFontLoaded,
      })}
    >
      <div className="preloader__markers">
        {/* CORNERS */}
        <div className="preloader-corners">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className="preloader-label">
        <span>Loading</span>
      </div>
      <div className="preloader-number">
        <div className="preloader-number__inner">
          <Cross className="preloader-number-cross is-one" />
          <Cross className="preloader-number-cross is-two" />
          <Cross className="preloader-number-cross is-three" />
          <Cross className="preloader-number-cross is-four" />
          {((loaded / total) * 100)
            .toFixed(0)
            .padStart(3, "0")
            .split("")
            .map((item, key) => (
              <span key={key}>{item}</span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Preloader;
