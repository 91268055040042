export const assetPath = (path: string) => {
  return `/assets/images${path}`;
}

export const assetPathHotel = (frame: number, folder: string, isMobile: boolean = false) => {
  let filenum = frame.toString();
  while (filenum.length < 5) filenum = "0" + filenum;
  const filename = `Hotel-${folder}-${filenum}.png`;
  const assetFolder = isMobile ? 'hotel-mobile' : 'hotel';
  const path = `/assets/images/${assetFolder}/${folder.toLowerCase()}/${filename}`;
  return path;
}

export const viewportY = (el: HTMLElement) => {
  const elBB = el.getBoundingClientRect();
  const elFactor = (elBB.top + elBB.height) / (window.innerHeight + elBB.height);
  return 1 - elFactor;
}

export const minMax = (val: number, valMin: number = 0, valMax: number = 1) => {
  let valReturn = val;
  if (valReturn < valMin) {
    valReturn = valMin;
  } else if (valReturn > valMax) {
    valReturn = valMax;
  }
  return valReturn;
}

export const inViewport = (el: HTMLElement) => {
  const vpY = viewportY(el);
  const inViewport = vpY >= 0 && vpY <= 1;
  return inViewport;
}

export const isMobileVP = () => {
  return window.innerWidth <= 768;
}

export const isDesktopVP = () => {
  return window.innerWidth >= 1024;
}

export const isIn = (el: HTMLElement) => {
  const vpY = viewportY(el);
  return !!(vpY < 1);
}

export const canIuseMixBlendMode = (id: string) => {
  if ('CSS' in window && 'supports' in window.CSS) {
    var support = window.CSS.supports('mix-blend-mode', 'multiply');
    const root = document.getElementById(id);
    root && root.classList.add(support ? 'mix-blend-mode' : 'no-mix-blend-mode');
  }
}