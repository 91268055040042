import classNames from "classnames";
import "./transition.scss";
import { MENU_PANEL } from "const/menuPanel.const";

interface transitionProps {
  isOpen: boolean;
}

const Transition: React.FC<transitionProps> = (props) => {
  const panels = Array(MENU_PANEL.panelLength);
  const tDuration = MENU_PANEL.tDuration;

  return (
    <div className={classNames("transition", { "is-open": props.isOpen })}>
      {Array.from(panels.keys()).map((item, key) => {
        return (
          <div
            key={key}
            style={{
              height: `${100 / panels.length}%`,
              transitionDuration: `${tDuration}s`,
              transitionDelay: `${(tDuration / MENU_PANEL.panelLength) * key}s`,
            }}
          />
        );
      })}
    </div>
  );
};

export default Transition;
