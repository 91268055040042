import Teaser, {teaserDataProps} from "./teaser";

interface teaserLoopProps {
  data: Array<teaserDataProps>;
  layout: string;
}

const TeaserLoop: React.FC<teaserLoopProps> = props => {
  return (
    <>
      {props.data.map((item, key) => <Teaser {...item} layout={props.layout} key={key} />)}
    </>
  )
}

export default TeaserLoop;
