import React, { useEffect, useRef } from "react";

import "./topbar.scss";
import { ReactComponent as Logo } from "static/topbar-logo.svg";
import { ReactComponent as LogoMobile } from "static/topbar-logo-mobile.svg";
import ButtonMove from "components/button/buttonMove";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

interface topBarProps extends RouteComponentProps {
  isMenuOpen?: boolean;
  onMenuOpen: () => void;
}

const Topbar: React.FC<topBarProps> = (props) => {
  const animationFrame = useRef<any>();
  const elRef = useRef<HTMLDivElement>(null);
  //
  useEffect(() => {
    const render = () => {
      if (elRef.current) {
        let delta = window.spHotelScrollY - (document.body.clientHeight - window.innerHeight);
        delta += elRef.current.scrollHeight;
        delta < 0 && (delta = 0);
        elRef.current.style.setProperty('transform', `translateY(-${delta}px)`);
      }
      animationFrame.current = requestAnimationFrame(render);
    };

    animationFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(animationFrame.current);
  }, []);

  return (
    <div
      className="topbar"
      ref={elRef}
    >
      <Link to="/" className="topbar-logo">
        <Logo />
      </Link>
      <Link to="/" className="topbar-logo-mobile">
        <LogoMobile />
      </Link>
      <div className="topbar__button-nav">
        <div className="topbar__button-nav-inner">
          <ButtonMove onClick={props.onMenuOpen} isActive={props.isMenuOpen}>
            Rooms
          </ButtonMove>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Topbar);
