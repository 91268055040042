import "./hotelScrub.scss";
import React from "react";

interface hotelScrubProps {
  index: number;
  unitCount: number;
  unitHeight: number;
}

const HotelScrub = React.forwardRef<HTMLDivElement, hotelScrubProps>(
  (props, ref) => {
    return (
      <div
        className="hotel-scrub"
        style={{
          height: `${props.unitCount * props.unitHeight}px`,
        }}
        data-index={props.index}
        ref={ref}
      ></div>
    );
  }
);

// const HotelScrub: React.FC<hotelScrubProps> = (props) => {
//   const isIn = () => {
//     return props.index;
//   }

//   return (
//     <div
//       className="hotel-scrub"
//       style={{
//         height: `${props.unitCount * props.unitHeight}px`,
//         position: "relative",
//       }}
//     ></div>
//   );
// };

export default HotelScrub;
