import Exhibitions from "containers/exhibitions/exhibitions";
import Legal from "containers/legal/legal";
import Lobby from "containers/lobby/lobby";
import Salon from "containers/salon/salon";
import Schedule from "containers/schedule/schedule";
import Studio from "containers/studio/studio";

interface routerProps {
  page: string;
}

const Router: React.FC<routerProps> = (props) => {
  document.body.classList.remove("is-topbar-transparent");
  // 
  let component = null;
  switch (props.page) {
    case "/exhibitions":
      component = <Exhibitions />;
      break;

    case "/salon":
      component = <Salon />;
      break;

    case "/studio":
      component = <Studio />;
      break;

    case "/schedule":
      component = <Schedule />;
      break;

    case "/terms-conditions":
      component = <Legal dataKey="terms" />;
      break;

    case "/privacy-policy":
      component = <Legal dataKey="privacy" />;
      break;

    default:
      component = <Lobby />;
      break;
  }
  return component;
};

export default Router;
