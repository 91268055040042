import "./menuItem.scss";
import Cross from "components/cross/cross";
import HotelTitleNum from "components/hotel/hotelTitleNum";
import { Link, useHistory } from "react-router-dom";
import * as utils from "shell/app/app.utils";

interface menuItemProps {
  id: string;
  index: number;
  title: string;
}

const MenuItem: React.FC<menuItemProps> = (props) => {
  const history = useHistory();

  return (
    <div
      className="menu-item"
      data-is-current={`/${props.id}` === history.location.pathname}
    >
      <div className="menu-item__inner">
        <Link to={`/${props.id}`} className="menu-item__link">
          {/* onClick={handleButtonClick} data-link={`/${item.id}`} */}
          <Cross className="menu-item__cross is-left is-white" />
          <Cross className="menu-item__cross is-right is-white" />
          <Cross className="menu-item__cross is-bottom-left is-white" />
          <Cross className="menu-item__cross is-bottom-right is-white" />
          <div className="menu-item__title">
            <HotelTitleNum index={props.index} />
            <div className="menu-item__title-area">
              <span>{props.title}</span>
              <span>{props.title}</span>
            </div>
          </div>
          <div className="menu-item__visual">
            <img src={utils.assetPath(`/menu/hotel-${props.index}.png`)} alt="" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MenuItem;
