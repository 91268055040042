import { useRef } from "react";
import classNames from "classnames";

import "./menu.scss";
import data from "data/menu.data.json";
import MenuItem from "./menuItem";
import ButtonMove from "components/button/buttonMove";

interface menuProps {
  onMenuClose?: () => void;
  isFooter?: boolean;
  isOpen: boolean;
}

const Menu: React.FC<menuProps> = (props) => {
  const elRef = useRef<HTMLDivElement>(null!);
  //
  return (
    <div
      className={classNames("menu", {
        "is-open": props.isOpen,
        "is-footer": props.isFooter,
      })}
      ref={elRef}
    >
      {/* INNER */}
      <div className="menu-inner">
        {/* LINES */}
        <div className="menu-inner-lines">
          <div />
          <div />
        </div>
        <div className="menu-inner__markers">
          {/* CORNERS */}
          <div className="menu-inner-corners">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <div className="menu-inner__elements">
          {/* TITLE */}
          <div className="menu-inner-title">
            <div>Rooms</div>
          </div>
          {/* MENU */}
          <div className="menu-inner-menu">
            {data.menu.map((item, key) => (
              <MenuItem {...item} key={key} index={key} />
            ))}
          </div>
        </div>
      </div>
      {/* LEGAL LINKS */}
      {!!props.isFooter && (
        <div className="menu-legal">
          {data.legal.map((item, key) => (
            <ButtonMove to={item.href} key={key} isWhite isExternal>{item.label}</ButtonMove>
          ))}
        </div>
      )}
      {/* CLOSE */}
      {!props.isFooter && (
        <button className="menu-close" onClick={props.onMenuClose}></button>
      )}
    </div>
  );
};

export default Menu;
