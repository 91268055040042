import "./teaser.scss";
import TeaserExhibitions from "./teaserExhibitions";
import TeaserSalon from "./teaserSalon";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

export interface teaserDataProps {
  title: string;
  copy: Array<string>;
  link: {
    label: string;
    href: string;
  };
  images: Array<string>;
  isImgBig?: boolean;
  isReverse?: boolean;
  sponsor?: string;
  sponsorIcon?: string;
  subtitle?: string;
}

interface teaserProps extends teaserDataProps {
  layout: string;
}

const Teaser: React.FC<teaserProps> = (props) => {
  const [isIn, setIsIn] = useState<boolean>(false);

  const elRef = useRef<HTMLDivElement>(null!);
  const animationFrame = useRef<any>();

  useEffect(() => {
    const render = () => {
      if (!!elRef.current) {
        const box = elRef.current.getBoundingClientRect();
        const _isIn = box.top <= window.innerHeight*0.95;
        isIn !== _isIn && setIsIn(_isIn);
      }
      animationFrame.current = requestAnimationFrame(render);
    };
    animationFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(animationFrame.current);
  }, [isIn]);

  const doSwitch = () => {
    let comp = null;
    switch (props.layout) {
      case "exhibitions":
        comp = <TeaserExhibitions {...props} isIn={isIn} />;
        break;

      case "salon":
        comp = <TeaserSalon {...props} isIn={isIn} />;
        break;
    }

    return comp;
  };

  return (
    <div
      className={classNames(
        "teaser",
        `teaser--${props.layout}`,
        `is-img-${props.images.length}`,
        {
          "is-img-big": props.isImgBig,
          "is-reverse": props.isReverse,
          "is-in": isIn,
          "is-endless": !!(Math.random() * 10 < 5)
        }
      )}
    >
      <div className="teaser__wrapper">
        <div className="teaser__row" ref={elRef}>
          {/* CONTENT BLOCK */}
          {doSwitch()}
        </div>
      </div>
    </div>
  );
};

export default Teaser;
