import { useEffect, useRef, useState } from "react";

import "./imageWall.scss";
import * as utils from "shell/app/app.utils";
import classNames from "classnames";
import isMobile from "ismobilejs";

interface imageWallProps {
  data: Array<{
    debug?: boolean;
    img: string;
    isRotation?: boolean;
    offset?: string;
    plx?: number;
    plxMobile?: number;
    pos: string;
    posMobile?: string;
    rot?: number;
    z: number;
    noMobile?: boolean;
  }>;
  debug?: boolean;
  map3d?: boolean;
  section?: number;
  onScroll?: (section: number, inVP: boolean) => void;
}

const ImageWall: React.FC<imageWallProps> = (props) => {
  const inVPRef = useRef<boolean>(false);
  const elRef = useRef<HTMLDivElement>(null!);
  const imgAr = useRef<Array<HTMLDivElement | null>>([]);
  //
  const animationFrame = useRef<any>(0);
  const onMobile = useRef<boolean>(isMobile().any);
  const onScroll = props.onScroll;

  useEffect(() => {
    const vpOnScroll = () => {
      if (!!onScroll) {
        if (elRef.current) {
          const box = elRef.current.getBoundingClientRect();
          const posY = -box.top + window.innerHeight;
          // if (props.debug) {
          //   console.log(posY, box.height + window.innerHeight)
          // }
          const inVP =
            posY >= (window.innerHeight - box.height) / 2 &&
            posY <= box.height + (window.innerHeight - (window.innerHeight - box.height));
          if (inVP !== inVPRef.current) {
            inVPRef.current = inVP;
            onScroll(props.section || 0, inVP);
          }
        }
      }
    };

    // const doParallax = () => {
    //   // if (!props.map3d) {
    //   //   imgAr.current.forEach((item) => {
    //   //     if (!!item) {
    //   //       // const plx = parseFloat(item.getAttribute("data-plx") || "0");
    //   //       const box = item.getBoundingClientRect();
    //   //       const vpPos = box.top + box.height;
    //   //       const vpMax = window.innerHeight + box.height;
    //   //       const vpY = vpPos / vpMax;
    //   //       // const vpCenter = utils.minMax((vpY - 0.5) * 2, -1.5, 1.5);
    //   //       // item.style.transform = `translateY(${box.height*vpCenter*plx}px)`
    //   //       // if (item.getAttribute("data-debug") === "true") {
    //   //       //   console.log(vpY, vpMax);
    //   //       // }
    //   //     }
    //   //   });
    //   // }
    // };

    const render = () => {
      vpOnScroll();
      // doParallax();
      animationFrame.current = requestAnimationFrame(render);
    };
    //
    animationFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(animationFrame.current);
  }, [onScroll, props.section]);

  return (
    <div className="image-wall" ref={elRef}>
      <div className="image-wall__wrapper">
        <div className="image-wall__images">
          {props.data.map((item, key) => {
            if (!onMobile.current || (onMobile.current && !item.noMobile)) {
              let pos = item.pos.split(",");
              if (!!onMobile.current && !!item.posMobile) {
                pos = item.posMobile.split(",");
              }
              return (
                <div
                  className="image-wall__image"
                  style={{ left: `${pos[0]}%`, top: `${pos[1]}%` }}
                  key={key}
                >
                  <div
                    className="image-wall__image-container"
                    ref={(ref) => (imgAr.current[key] = ref)}
                    data-plx={item.plx || 0}
                  >
                    <ResponsiveImage
                      data={item}
                      map3d={!!props.map3d}
                      onMobile={onMobile.current}
                    />
                  </div>
                </div>
              );
            }
            return false;
          })}
        </div>
      </div>
    </div>
  );
};

interface imageProps {
  data: any;
  map3d: boolean;
  onMobile: boolean;
}

const ResponsiveImage: React.FC<imageProps> = (props) => {
  const elRef = useRef<HTMLImageElement>(null!);
  const [size, setSize] = useState<{ width: string; height: string }>({
    width: "auto",
    height: "auto",
  });

  const handleImageLoad = () => {
    const el = elRef.current;

    const width = el.clientWidth;
    const height = el.clientHeight;

    const ratio = width / height;
    const ratioMinus = 1 - ratio;

    const basis = 100 + (100 / 100) * props.data.z;
    let _size = {
      height: `${basis + basis * (ratioMinus / 2)}%`,
      width: `${basis - basis * (ratioMinus / 2)}%`,
    };
    setSize(_size);
  };

  return (
    <img
      src={utils.assetPath(props.data.img)}
      className={classNames("image-wall__image-img", {
        "lobby-canvas-image": props.map3d,
      })}
      data-debug={!!props.data.debug}
      data-offset={props.data.offset || "0,0"}
      data-plx={
        props.onMobile && !!props.data.plxMobile
          ? props.data.plxMobile
          : props.data.plx || 0
      }
      data-is-rotation={props.data.isRotation || false}
      data-z={props.data.z}
      alt=""
      ref={elRef}
      height={size.height}
      width={size.width}
      onLoad={handleImageLoad}
    />
  );
};

export default ImageWall;
