import { useEffect, useRef } from "react";
import "./marquee.scss";

interface marqueeProps {
  copy: string;
  isActive: boolean;
}

const Marquee: React.FC<marqueeProps> = (props) => {
  const elRef = useRef<HTMLDivElement>(null!);
  const slideRef = useRef<any>(null!);
  const itemRef = useRef<HTMLDivElement>(null!);
  const animationFrame = useRef<any>();
  //
  const left = useRef<number>(0);
  const speed = 1;
  //
  useEffect(() => {
    const render = () => {
      left.current = (left.current - speed) % itemRef.current.clientWidth;
      if (slideRef.current) {
        slideRef.current.style.transform = `translateX(${left.current.toFixed(5)}px)`
      }

      props.isActive &&
        (animationFrame.current = requestAnimationFrame(render));
    };
    if (props.isActive) {
      props.isActive &&
        (animationFrame.current = requestAnimationFrame(render));
    }
    return () => cancelAnimationFrame(animationFrame.current);
  }, [props.isActive]);

  return (
    <div className="marquee" ref={elRef}>
      <div className="marquee__inner" ref={slideRef}>
        <div className="marquee__el" ref={itemRef}>
          {props.copy}
        </div>
        <div className="marquee__el">{props.copy}</div>
      </div>
    </div>
  );
};

export default Marquee;
