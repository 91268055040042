import React from "react";
import classNames from "classnames";

import "./lobbyOverlay.scss";
import Cross from "components/cross/cross";
import ButtonSolid from "components/button/buttonSolid";

interface lobbyOverlayProps {
  button: string;
  headline: string;
  id: string;
  isIn: boolean;
}

const LobbyOverlay: React.FC<lobbyOverlayProps> = (props) => {
  return (
    <div
      className={classNames("lobby-overlay", {
        "is-in": props.isIn,
      })}
    >
      <div className="lobby-overlay__wrapper">
        <div className="lobby-overlay__inner">
          <Cross className="lobby-overlay__cross is-cross-one" />
          <Cross className="lobby-overlay__cross is-cross-two" />
          <div className="lobby-overlay__content">
            <h3 className="lobby-overlay__headline">{props.headline}</h3>
          </div>
        </div>
        <div className="lobby-overlay__button">
          <ButtonSolid to={`/${props.id}`}>{props.button}</ButtonSolid>
        </div>
      </div>
    </div>
  );
};

export default LobbyOverlay;
