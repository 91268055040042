import "./hotelTitleNum.scss";

interface hotelTitleNumProps {
  index: number;
}

const HotelTitleNum: React.FC<hotelTitleNumProps> = (props) => (
  <div className="hotel-title-num">0{props.index}</div>
);

export default HotelTitleNum;
