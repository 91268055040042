import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import "./image2k.scss";

interface image2kProps {
  isHeader?: boolean;
  src: string;
}

const Image2k: React.FC<image2kProps> = (props) => {
  const [isIn, setIsIn] = useState<boolean>(false);

  const elRef = useRef<HTMLDivElement>(null!);
  const animationFrame = useRef<any>();

  const panels = Array(10);
  const tDuration = 0.5;
  const tDelay = !!props.isHeader && window.innerWidth > 960 ? 1 : 0;

  useEffect(() => {
    const render = () => {
      if (!!elRef.current) {
        const box = elRef.current.getBoundingClientRect();
        const _isIn = box.top <= window.innerHeight;
        isIn !== _isIn && setIsIn(_isIn);
      }
      animationFrame.current = requestAnimationFrame(render);
    };
    animationFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(animationFrame.current);
  }, [isIn]);

  return (
    <div className={classNames("image2k", {"is-in": !!isIn})} ref={elRef}>
      <div className="image2k-panels">
        {Array.from(panels.keys()).map((item, key) => {
          return (
            <div
              key={key}
              style={{
                height: `${100 / panels.length}%`,
                transitionDuration: `${tDuration}s`,
                transitionDelay: `${(tDuration / panels.length) * key + tDelay}s`,
              }}
            />
          );
        })}
      </div>
      <img src={props.src} alt="" />
    </div>
  );
};

export default Image2k;
