import { Suspense, useEffect, useRef, useState, useMemo } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import isEqual from "lodash/isEqual";
import imagesLoaded from "imagesloaded";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import "components/lobby3d/lobby3dImageMaterial";
import * as utils from "shell/app/app.utils";

const Lobby3dImage = (props) => {
  const texture = useMemo(
    () => new TextureLoader().load(props.src),
    [props.src]
  );
  //
  const [bounds, setBounds] = useState(new Image().getBoundingClientRect());
  //
  const material = useRef();
  const meshRef = useRef();
  const { camera } = useThree();

  useFrame(() => {
    const offset = {
      x: (props.offset[0] / 100) * (camera.rotation.x * 5) * bounds.width,
      y: (props.offset[1] / 100) * (camera.rotation.y * 5) * bounds.height,
    };

    if (meshRef.current) {
      const y = bounds.top + window.spHotelScrollY;
      const x = bounds.left + offset.x;

      // vpY
      const vpPos = props.canvasSize.height + y + bounds.height / 2;
      const vpMax = props.canvasSize.height + bounds.height;
      const vpY = vpPos / vpMax;
      const vpYcenter = utils.minMax((vpY - 0.5) * 2, -1.5, 1.5);
      // parallax
      const parallaxY = bounds.height * props.plx * vpY;
      meshRef.current.position.x = x;
      meshRef.current.position.y =
        y + parallaxY + offset.y + props.canvasSize.height / 2;
      meshRef.current.position.z = props.z;

      if (props.isRotation) {
        const rotationY =
          (-0.5 / (window.innerWidth / 2)) *
          (meshRef.current.position.x - window.innerWidth / 2);
        meshRef.current.rotation.y = rotationY - rotationY * 0.5 * vpYcenter;
      }
      // material opacity
      if (material.current) {
        material.current.uOpacity = utils.minMax(1 - (camera.zoom - 1) / 0.125);
      }
    }
  });

  useEffect(() => {
    const reBound = () => {
      const box = props.img.getBoundingClientRect();
      const _bounds = {
        height: box.height,
        left: box.left + box.width / 2,
        top: -box.top - box.height / 2 - window.spHotelScrollY,
        width: box.width,
      };
      !isEqual(bounds, _bounds) && setBounds(_bounds);
    };

    if (!!props.img && !!props.canvasSize) {
      reBound();
      imagesLoaded(props.img, () => {
        reBound();
      });
    }
    window.addEventListener("resize", reBound);
    return () => window.removeEventListener("resize", reBound);
  }, [props.img, props.canvasSize, bounds]);

  return (
    <Suspense fallback={null}>
      <mesh ref={meshRef}>
        <planeGeometry
          attach="geometry"
          args={[bounds.width, bounds.height, 10, 10]}
        />
        <waveMaterial
          ref={material}
          attach="material"
          uiImage={texture}
          side="DoubleSide"
          transparent={true}
        />
      </mesh>
    </Suspense>
  );
};

export default Lobby3dImage;
