import "./footer.scss";
import Menu from "components/menu/menu";

interface footerProps {}

const Footer: React.FC<footerProps> = (props) => {
  //
  return (
    <div className="footer">
      <div className="footer-menu">
        <Menu isOpen={true} isFooter={true} />
      </div>
    </div>
  );
};

export default Footer;
