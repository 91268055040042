import "./teaserSalon.scss";
import { teaserDataProps } from "./teaser";
import * as utils from "shell/app/app.utils";
import ButtonMove from "components/button/buttonMove";
import Image2k from "components/image2k/image2k";
import Cross from "components/cross/cross";

interface teaserSalonProps extends teaserDataProps {
  isIn: boolean;
}

const TeaserSalon: React.FC<teaserSalonProps> = (props) => {
  return (
    <>
      <Cross className="teaser-cross--img" />
      <div className="teaser-block  teaser-block--image">
        <Image2k src={utils.assetPath(props.images[0])} />
      </div>
      <div className="teaser-group">
        <div className="teaser-block teaser-block--title">
          <h5>{props.title}</h5>
        </div>
        <div className="teaser-block teaser-block--copy">
          <p>{props.copy}</p>
          <ButtonMove to={props.link.href} withIcon isExternal className="teaser-button">
            {props.link.label}
          </ButtonMove>
          <div className="teaser-block__image-bottom">
            {!!props.images[1] && (
              <Image2k src={utils.assetPath(props.images[1])} />
            )}
          </div>
        </div>
        <Cross className="teaser-cross--img-2" />
      </div>
    </>
  );
};

export default TeaserSalon;
